* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.header-main-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 20vh;
    background-color: #686963;
    padding: 0rem;
    overflow: auto;
    z-index: 1000;
    position: absolute;
    will-change: transform;
}

.titulo-chiguire-resto {
    text-align: center;
    font-size: 1.8rem;
    font-weight: bolder;
    margin-bottom: 0rem;
    padding: 0rem;
    white-space: nowrap;
    padding-bottom: 0.3rem;
}

.titulo-chiguire-resto-enlace {
    color: #FFFFFF;
    text-decoration: none;
}

.reservar-header {
    color: #48BF84;
    text-decoration: none;
    margin-right: 0.5rem;
    margin-left: 4rem;
    font-size: 0.8rem;
    font-weight: bold;
}

.reservar-header:hover {
    text-decoration: underline #000000 0.1rem solid;
}


.lista-de-iconos-menu {
    display: flex;
    list-style-type: none;
    margin-bottom: 0rem;
    font-size: 0.8rem;
}

@keyframes animacion-iconos-header {
    from {
        color: #ffffff;
    }

    to {
        color: #48BF84;
    }
}

.lista-de-iconos-menu li a {
    color: #ffffff;
    margin-right: 1.4rem;
    font-size: 0.9rem;
}

.lista-de-iconos-menu li a:hover {
    animation-name: animacion-iconos-header;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in;
}

.contenedor-principal-hamburger {
    max-width: 20%;
}

@media screen and (max-width:767px) {
    .header-main-container {
        height: 20vh;    
    }
    .titulo-chiguire-resto {
        width: 30vw;
        margin-left: 1.5rem;
        white-space: normal;
        text-align: start;
    }
    .reservar-header {
        display: none;
    }

    .lista-de-iconos-menu li a {
        display: none;
    }
}

@media screen and (max-width:816px) {
    .reservar-header {
        display: none;
    }
}