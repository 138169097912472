.container-fluid {
  padding: 0rem;
  width: 100%;
}

/*Configuración Especial Headear 576px*/

@media screen and (max-width: 576px) {

  #contenedor-principal-chiguire-resto {
    display: flex;
    justify-content: start;
    width: 50%;
  }

  #contenedor-principal-chiguire-resto div {
    display: flex;
    justify-content: space-around;
    width: 50%;
  }

  #contenedor-principal-chiguire-resto div a h1 {
    font-size: 1.9rem;
    margin-left: 0rem;
    width: 40vw;
  }
}

@media screen and (max-width: 382px) {
  #contenedor-principal-chiguire-resto div a h1 {
    font-size: 1.7rem;
    width: 55vw;
    margin-left: 0.5rem;
  }
}

/*fondo de la página */

.contenedor-principal-webpage {
  position: relative;
  width: 100vw;
  height: 100vh;
  background-color: #48BF84;
}

/*Contenedor Principal de la página*/

body {
  margin: 0;
  overflow-x: hidden;
}

.contenedor-contenido-completo-webpage {
  width: 100vw;
  height: 100vh;
  transition: transform 0.5s ease width 0.5s ease, height 0.5s ease;
}

.zoom-out {
  overflow: hidden;
  max-width: none;
  width: 100%;
  transform: scale(0.75) translate(-50%, -0%);
  animation-name: moverYEscalar;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
}

.zoom-in {
  position: absolute;
  animation-name: animacion-volver-posicion-original;
  animation-timing-function: ease-out;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  transform: none;
}

@keyframes moverYEscalar {
  from {
    transform: scale(1) translate(0, 0);
  }

  to {
    transform: scale(0.75) translate(-50%, -0%);
  }
}

@keyframes animacion-volver-posicion-original {
  0% {
    transform: scale(0.75) translate(-50%, -0%);
  }

  100% {
    transform: scale(1) translate(0, 0);
  }
}

.contenedor-contenido-webpage {
  background-color: #FFFFFF;
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  .contenedor-contenido-webpage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

/* Aviso Cookies */

.aviso-cookies {
  display: none;
  background-color: #FFFFFF;
  padding: 20px;
  width: calc(100% - 40px);
  max-width: 300px;
  border-radius: 10px;
  bottom: 20px;
  left: 20px;
  position: fixed;
  z-index: 100;
  padding-top: 60px;
  box-shadow: 0px 2px 20px 10px rgba(222,222,222, .25);
  text-align: center;
}

.aviso-cookies.activo {
  display: block;
}

.aviso-cookies .galleta {
  max-width: 100px;
  position: absolute;
  top: -50px;
  left: calc(50% - 50px);
}

.aviso-cookies .titulo-cookies, .aviso-cookies .parrafo-cookies {
  margin-bottom: 15px;
}

.aviso-cookies .boton-aceptar-cookies {
  width: 100%;
  background-color: #595959;
  border: none;
  color: #FFF;
  font-family: "Prompt", sans-serif;
  text-align: center;
  padding: 15px 20px;
  font-weight: 700;
  cursor: pointer;
  transition: .3s ease all;
  border-radius: 5px;
  margin-bottom: 15px;
  font-size: 1rem;
}

.aviso-cookies .boton-aceptar-cookies:hover {
  background-color: #000;
}

.aviso-cookies .enlace-cookies {
  color: #4DBFFF;
  text-decoration: none;
  font-size: 1rem;
}

.aviso-cookies .enlace-cookies:hover {
  color: #4DBFFF;
  text-decoration: underline;
  font-size: 1rem;
}

.fondo-aviso-cookies {
  display: none;
  background: rgba(0, 0, 0, .20);
  position: fixed;
  z-index: 99;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
}

.fondo-aviso-cookies.activo {
  display: block;
}