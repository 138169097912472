* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

.footer-main-container {
    width: 100%;
    background-color: #686963;
    overflow: hidden;
}

.logo-footer {
    width: 5rem;
    padding-top: 1.3rem;
    padding-bottom: 0.5rem;
}

.titulo-footer {
    color: #FFFFFF;
    text-align: center;
    font-weight: bolder;
}

.redes-sociales-footer {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 1rem;
    padding-left: 0rem;
    font-size: 1.5rem;
}

.redes-sociales-footer li {
    list-style-type: none;
}

@keyframes animacion-iconos-header {
    from {color: #ffffff;}
    to {color: #48BF84;}
}

.redes-sociales-footer li a {
    color: #FFFFFF;
}

.redes-sociales-footer li a:hover {
    animation-name: animacion-iconos-header;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in;
}

.subtitulos-contacto {
    color: #000000;
    font-weight: bolder;
    text-align: center;
}

.email-contacto {
    color: #48BF84;
    text-align: center;
    font-style: italic;
}

.lista-legal-footer li {
    list-style-type: none;
    text-align: center;
}


.lista-legal-footer li  a{
    color: #48BF84;
    padding-left: 0rem;
    text-decoration: none;
}

.lista-legal-footer li  a:hover {
    text-decoration: underline #48BF84 0.1rem;
}

.lista-legal-footer {
    padding-left: 0rem;
}

@media screen and (max-width:767px) { 
    .logo-footer {
        padding-top: 2rem;
        width: 7rem;
    }
    .titulo-footer {
        text-transform: uppercase;
        padding-top: 1.2rem;
        padding-bottom: 1.2rem;
    }
    .redes-sociales-footer li a {
        font-size: 1.8rem;
    }
    .lista-legal-footer li {
        padding-bottom: 0.6rem;
    }
    .lista-legal-footer li:last-child {
        padding-bottom: 1rem;
    }
}