.lista-menu {
    list-style-type: none;
    letter-spacing: 0.1rem;
    line-height: 2.5rem;
    padding-left: 0rem;
}

.lista-menu li a {
    font-weight: 600;
    font-size: 1.3rem;
    color: #FFFFFF;
    text-decoration: none;
}

.lista-menu li:nth-last-child(1) {
    margin-bottom: 0rem;
}

.lista-menu li a:hover {
    text-decoration: underline solid #EECF6D 0.3rem;
}

@media screen and (max-width: 768px) {
    .lista-menu li a {
        font-size: 1.1rem;
    }
}

@media screen and (max-width: 576px) {
    .lista-menu li a {
        font-size: 1rem;
    }   
}

@media screen and (max-width: 400px) {
    .contenedor-menu {
        width: 30%;
        height: 35%;
    }
    .lista-menu li {
        line-break: auto;
        line-height: 1.2rem;
        margin-bottom: 1.2rem;
        font-size: 0.5rem;
    }   
}

@media screen and (max-width: 300px) {
    .contenedor-menu {
        width: 25%;
        height: 50%;
        
    }

    .lista-menu li {
        line-break: auto;
        line-height: 1.2rem;
        margin-bottom: 1.2rem;
        font-size: 0.5rem;
    }   
}
