@import url('https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Zilla+Slab:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');

/* Imagen Principal */

.imagen-principal-main-container {
    margin-bottom: 10rem;
}

.contenedorImagenPrincipal {
    max-width: 40rem;
    min-width: 40rem;
    width: 50%;
    height: 340px;
    margin-top: 38vh;
    border-radius: 0.7rem;
    position: relative;
    background-image: url('../../Galeria-Recursos/HomePage/Imagen-Principal/receta-de-asado-negro-venezolano.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

/* Contenedor Primer Texto */

.contenedorImagenPrincipal div {
    position: absolute;
    transform: translate(-135px, -68px);
    line-height: 2.5rem;
}

.segundoTexto {
    position: absolute;
    transform: translate(160px, 295px);
}

/* Texto */

.contenedorImagenPrincipal div small {
    font-size: 1.6rem;
    font-family: "Prompt", sans-serif;
}

.contenedorImagenPrincipal div p {
    font-size: 3.3rem;
    font-weight: 700;
}

.contenedorImagenPrincipal p {
    font-size: 3.3rem;
    font-weight: 700;
    font-family: "Prompt", sans-serif;
}

/* Media Queries */

@media screen and (min-width: 1200px) {
    .contenedorImagenPrincipal div {
        transform: translate(-50px, -50px);
        line-height: 1.8rem;
    }

    .segundoTexto {
        transform: translate(200px, 313px);
    }

    .contenedorImagenPrincipal div p {
        font-size: 2.1rem;
    }

    .contenedorImagenPrincipal p {
        font-size: 2.1rem;
    }
}

@media screen and (max-width: 1200px) {

    .contenedorImagenPrincipal {
        margin-top: 18rem;
    }

    .contenedorImagenPrincipal div {
        transform: translate(-50px, -50px);
        line-height: 1.8rem;
    }

    .segundoTexto {
        transform: translate(200px, 313px);
    }

    .contenedorImagenPrincipal div p {
        font-size: 2.1rem;
    }

    .contenedorImagenPrincipal p {
        font-size: 2.1rem;
    }
}

@media screen and (max-width: 992px) {
    .contenedorImagenPrincipal div {
        transform: translate(-50px, -50px);
        line-height: 1.8rem;
    }

    .segundoTexto {
        transform: translate(200px, 313px);
    }

    .contenedorImagenPrincipal div p {
        font-size: 2.1rem;
    }

    .contenedorImagenPrincipal p {
        font-size: 2.1rem;
    }
}

@media screen and (max-width: 992px) {

    .contenedorImagenPrincipal div {
        transform: translate(3px, -80px);
        line-height: 1.8rem;
    }

    .contenedorImagenPrincipal div small {
        font-size: 1.5rem;
    }

    .segundoTexto {
        transform: translate(3px, 306px);
        white-space: pre;
    }

    .contenedorImagenPrincipal div p {
        width: 90%;
        font-size: 2.5rem;
        line-height: 2.9rem;
    }

    .contenedorImagenPrincipal p {
        font-size: 2.5rem;
    }
}

@media screen and (max-width: 768px) {
    .contenedorImagenPrincipal {
        min-width: 32rem;
        width: 30%;
        height: 270px;
        margin-top: 20rem;
    }

    .contenedorImagenPrincipal div {
        transform: translate(3px, -70px);
        line-height: 1.8rem;
    }

    .contenedorImagenPrincipal div p {
        width: 90%;
        font-size: 2.1rem;
        line-height: 2.2rem;
    }

    .contenedorImagenPrincipal div small {
        font-size: 1.4rem;
    }

    .segundoTexto {
        transform: translate(3px, 240px);
        white-space: pre;
    }

    .contenedorImagenPrincipal p {
        font-size: 2.1rem;
    }
}

@media screen and (max-width: 576px) {
    .contenedorImagenPrincipal {
        min-width: 26rem;
        width: 30%;
        height: 260px;
        margin-top: 20rem;
    }

    .contenedorImagenPrincipal div p {
        width: 90%;
        font-size: 1.7rem;
        line-height: 2.2rem;
    }

    .contenedorImagenPrincipal div small {
        font-size: 1.3rem;
    }

    .contenedorImagenPrincipal div {
        transform: translate(3px, -68px);
        line-height: 1.8rem;
    }

    .segundoTexto {
        transform: translate(3px, 237px);
        white-space: pre;
    }

    .contenedorImagenPrincipal p {
        font-size: 1.7rem;
    }
}

@media screen and (max-width: 460px) {
    .contenedorImagenPrincipal {
        min-width: 22rem;
        width: 30%;
        height: 225px;
    }

    .contenedorImagenPrincipal div p {
        width: 90%;
        font-size: 1.5rem;
        line-height: 1.8rem;
    }

    .contenedorImagenPrincipal div small {
        font-size: 1.2rem;
    }

    .contenedorImagenPrincipal div {
        transform: translate(3px, -60px);
        line-height: 1.8rem;
    }

    .segundoTexto {
        transform: translate(3px, 200px);
        white-space: pre;
    }

    .contenedorImagenPrincipal p {
        font-size: 1.5rem;
    }
}

@media screen and (max-width: 380px) {
    .contenedorImagenPrincipal {
        min-width: 18rem;
        width: 30%;
        height: 180px;
    }

    .contenedorImagenPrincipal div p {
        width: 90%;
        font-size: 1.2rem;
        line-height: 1.5rem;
    }

    .contenedorImagenPrincipal div small {
        font-size: 1.1rem;
    }

    .contenedorImagenPrincipal div {
        transform: translate(3px, -50px);
        line-height: 1.5rem;
    }

    .segundoTexto {
        transform: translate(3px, 160px);
        white-space: pre;
    }

    .contenedorImagenPrincipal p {
        font-size: 1.2rem;
    }
}

/*Descubre nuestra carta*/

.contenedor-principal-descubre-nuestra-carta {
    width: 100%;
    background-color: #EECF6D;
    overflow: hidden;
}

.descubre-nuestra-carta-imagen {
    width: 100%;
    height: 20rem;
    object-fit: cover;
    position: relative;
    background-image: url('../../Galeria-Recursos/HomePage/descubre-nuestra-carta/descubre-nuestra-carta.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.descubre-nuestra-carta-letra {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    height: 100%;
    width: 100%;
}

.descubre-nuestra-carta-letra h3 {
    color: #FFFFFF;
    font-weight: 700;
    margin-bottom: 1.5rem;
    font-family: "Prompt", sans-serif;
}

.descubre-nuestra-carta-letra p {
    font-family: Arial;
    width: 65%;
    font-size: 1.3rem;
    font-weight: 550;
    margin-bottom: 1.5rem;
    font-family: "Zilla Slab", system-ui;;
}

.boton-acceder-a-menu {
    color: #FFFFFF;
    padding: 1rem;
    border-radius: 1rem;
    border: none;
    background-color: #48BF84;
    font-size: 1.3rem;
    font-weight: 700;
    text-align: center;
    text-decoration: none;
    width: 7rem;
}

.boton-acceder-a-menu:hover {
    animation-name: animacion-seleccion-boton;
    animation-duration: 0.2s;
    animation-timing-function: ease-in;
    animation-fill-mode: forwards;
}

@keyframes animacion-seleccion-boton {
    from {
        font-size: 1.3rem;
    }

    to {
        font-size: 1.4rem;
    }
}

@media screen and (max-width: 767.9px) {
    .contenedor-principal-descubre-nuestra-carta {
        width: 80%;
        background-color: #EECF6D;
        overflow: hidden;
    }

    .descubre-nuestra-carta-letra {
        padding-top: 2rem;
        padding-bottom: 2rem;
        padding-right: 2rem;
        padding-left: 2rem;
    }

    .descubre-nuestra-carta-imagen {
        height: 12rem;
    }
}

/* Linea de Separacion */

.linea-de-separacion {
    width: 70%;
    margin-right: auto;
    margin-left: auto;
    margin-top: 3rem;
    margin-bottom: 3rem;
    border-color: #48BF84;
    border-style: solid;
    border-width: 0.3rem;
    border-radius: 1rem;
}

/* Galeria de Imaganes Y Texto */

.contenedor-principal-galeria-imagenes {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #EECF6D;
    margin-bottom: 7rem;
    overflow: hidden;
}

.contenedor-galeria-imagen1 {
    width: 100%;
    height: 16rem;
    object-fit: cover;
    position: relative;
    background-image: url('../../Galeria-Recursos/HomePage/Galeria-de-Imagenes/arepas.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.contenedor-galeria-imagen2 {
    width: 100%;
    height: 16rem;
    object-fit: cover;
    position: relative;
    background-image: url('../../Galeria-Recursos/HomePage/Galeria-de-Imagenes/cachapa.jpeg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.contenedor-galeria-imagen3 {
    width: 100%;
    height: 16rem;
    object-fit: cover;
    position: relative;
    background-image: url('../../Galeria-Recursos/HomePage/Galeria-de-Imagenes/empanadas.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.contenedor-galeria-imagen4 {
    width: 100%;
    height: 16rem;
    object-fit: cover;
    position: relative;
    background-image: url('../../Galeria-Recursos/HomePage/Galeria-de-Imagenes/tequeños.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.blanco-y-negro-mostrar {
    filter: grayscale(1);
    transition: filter 2s ease-in;
}

.blanco-y-negro-cerrar {
    filter: grayscale(0);
}

.contenedor-galeria-texto {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: start;
    padding-left: 1rem;
}

.contenedor-galeria-texto h3 {
    color: #FFFFFF;
    letter-spacing: 0.03rem;
    font-weight: 700;
    font-size: 2rem;
    margin-bottom: 1rem;
    font-family: "Prompt", sans-serif;
}

.contenedor-galeria-texto p {
    color: #000000;
    font-size: 1.2rem;
    font-weight: 500;
    font-family: "Zilla Slab", system-ui;;
    width: 65%;
}

@media screen and (max-width:767.9px) {
    .contenedor-principal-galeria-imagenes {
        width: 80%;
    }

    .contenedor-galeria-texto {
        padding: 2rem 1rem 2rem 1rem;
        height: 13rem;
    }

    .contenedor-galeria-texto p {
        width: 100%;
    }
}

/* Testimonio */

.testimonio-content {
    max-width: 65%;
    min-width: 55%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-bottom: 7rem;
}

/* Configuración de Comillas Testimonio */

.imagen-testimonio {
    width: 4rem;
    height: 4rem;
    filter: invert(61%) sepia(33%) saturate(746%) hue-rotate(77deg) brightness(93%) contrast(85%);
    margin-bottom: 1rem;
}

/* Estilos texto Testimonio */

.texto-testimonio {
    max-width: 65%;
    text-align: center;
    font-size: 1.4rem;
    font-family: "Zilla Slab", system-ui;;
    margin-bottom: 1.5rem;
    opacity: 0;
}

/* Configuración de Flechas Testimonio */

.testimonio-content p {
    transition: opacity 0.5s ease-in-out;
}

.testimonio-content button {
    background-color: transparent;
    border: none;
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    cursor: pointer;
}

.testimonio-flecha-hacia-la-izquierda button {
    left: 0rem;
}


.testimonio-flecha-hacia-la-derecha button {
    right: 0rem;
}

.testimonio-flecha-hacia-la-derecha button img {
    filter: invert(61%) sepia(33%) saturate(746%) hue-rotate(77deg) brightness(93%) contrast(85%);
    transform: rotate(90deg);
}

.testimonio-flecha-hacia-la-izquierda button img {
    filter: invert(61%) sepia(33%) saturate(746%) hue-rotate(77deg) brightness(93%) contrast(85%);
    transform: rotate(-90deg);
}

/* Detalles Testimonio */

.detalles-testimonio {
    display: flex;
    flex-direction: column;
    text-align: center;
    transition: opacity 0.5s ease-in-out;
    opacity: 0;
}

.detalles-testimonio p:nth-child(1) {
    margin: 0rem;
    font-weight: 700;
    font-size: 1.2rem;
    font-family: "Prompt", sans-serif;
    white-space: pre;
}

.detalles-testimonio p:nth-child(2) {
    font-weight: 500;
    font-size: 1.1rem;
    font-family: "Zilla Slab", system-ui;;
}

/* Transición */

.visible {
    opacity: 1;
}

.notvisible {
    opacity: 0;
}

/* Horarios y Direccion */

.contenedor-principal-info-visita {
    display: flex;
    align-items: center;
    background-color: #EECF6D;
    height: 27rem;
    overflow: hidden;
}

.contenedor-info-visita-texto {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    margin-left: 2rem;
    height: 100%;
}

.info-visita-imagen-container {
    width: 100%;
    height: 100%;
    padding-right: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.info-visita-imagen {
    width: 92%;
    height: 130%;
    object-fit: cover;
    position: relative;
    background-image: url('../../Galeria-Recursos/HomePage/ven-a-disfrutar-de-sabores-unicos/parrillada.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    ;
}

.info-titulo-container {
    margin-bottom: 1rem;
}

.info-titulo-container h3 {
    font-size: 1.8rem;
    font-weight: 700;
    font-family: "Prompt", sans-serif;
    letter-spacing: 0.05rem;
    white-space: pre;
}

.info-direccion-container {
    font-size: 1.4rem;
    font-family: "Zilla Slab", system-ui;;
    margin-bottom: 1.5rem;
}

.contenedor-info-horario-telf {
    display: flex;
    justify-content: flex-start;
    width: 100%;
}

.contenedor-info-horario-telf ul {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    list-style-type: none;
    font-family: "Prompt", sans-serif;
    padding-left: 0rem;
}

.contenedor-info-horario-telf ul li {
    display: flex;
    align-items: center;
}

.contenedor-info-horario-telf ul li i {
    font-size: 2.8rem;
}

.contenedor-info-horario-telf ul li:nth-child(2) {
    margin-left: 1rem;
}

.contenedor-texto-reserva a:hover {
    animation-name: animacion-reserva;
    animation-duration: .3s;
    animation-timing-function: ease-in;
    animation-fill-mode: forwards;
}

.contenedor-texto-reserva a small:nth-child(1) {
    text-decoration: underline 0.2rem #000000;
}

.contenedor-texto-reserva {
    margin-left: 1rem;
}

.contenedor-texto-reserva small {
    display: flex;
    flex-direction: column;
    font-size: 1.2rem;
    font-weight: 600;
}

.contenedor-texto-reserva a {
    text-decoration: none;
    color: #000000;
}

/* Animación Horarios y Dirección */

@keyframes animacion-reserva {
    from {
        color: #000000;
    }

    to {
        color: #48BF84;
    }
}

@keyframes animacion-text-decoration-retirar {
    from {
        text-decoration: underline 0.2rem #000000;
    }

    to {
        text-decoration: none;
    }
}

/* Media Queries Horarios y Direccion */

@media screen and (min-width: 1200px) {
    .info-visita-imagen {
        height: 150%;
    }
}

@media screen and (max-width: 1200px) {
    .info-visita-imagen {
        height: 130%;
    }
}

@media screen and (max-width: 992px) {
    .info-visita-imagen {
        height: 115%;
    }

    .contenedor-info-horario-telf ul {
        flex-direction: column;
    }

    .contenedor-info-horario-telf ul li:nth-child(1) {
        margin-bottom: 1rem;
    }

    .contenedor-info-horario-telf ul li:nth-child(2) {
        margin-left: 0rem;
    }
}

@media screen and (max-width: 768px) {
    .testimonio-flecha-hacia-la-izquierda button {
        left: -3rem;
    }

    .testimonio-flecha-hacia-la-derecha button {
        right: -3rem;
    }
}

@media screen and (width: 768px) {
    .contenedor-principal-info-visita {
        width: 100%;
    }

    .info-visita-imagen-container {
        padding-right: 1rem;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }
}

@media screen and (max-width: 767.9px) {

    .contenedor-principal-info-visita {
        display: flex;
        align-items: baseline;
        height: 35rem;
        width: 80%;
        overflow: hidden;
        margin-bottom: 7rem;
    }

    .info-visita-imagen-container {
        width: 100%;
        height: 15rem;
        padding-right: 0rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .info-visita-imagen {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: relative;
        background-image: url('../../Galeria-Recursos/HomePage/ven-a-disfrutar-de-sabores-unicos/parrillada.jpg');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        ;
    }

    .contenedor-info-visita-texto {
        padding-top: 1.3rem;
        padding-right: 1rem;
    }

    .info-titulo-container h3 {
        font-size: 1.6rem;
        letter-spacing: 0.13rem;
        line-height: 2rem;
        white-space: normal;
        margin-bottom: 0rem;
    }

    .contenedor-info-visita-texto {
        margin-left: 1.3rem;
    }

    .info-direccion-container {
        padding-right: 1rem;
    }
}

@media screen and (max-width:576px) {
    .contenedor-principal-info-visita {
        height: 40rem;
    }

    .info-visita-imagen-container {
        height: 20rem;
    }
}

/* Mapa */

.mapa-i-frame {
    width: 100%;
    height: 40vh;
}